import styled from "styled-components";
import { DEVICE, HEADER_HEIGHT } from "../../../utilities/constants";
export const StickyHeader = styled.div`
  background-color: #F5FBFC;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 52px;
  // border-bottom: 1px solid rgba(36, 119, 179, 0.1);
  @media ${DEVICE.tablet} {
    padding: 0px 15px;
  }
`;

export const MobileHeader = styled.div`
  background-color: #fff;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT + 1}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 52px;
  @media ${DEVICE.mobileL} {
    padding: 0px 15px;
  }
`;

export const LogoImageCont = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const LogoImage = styled.img`
  height: 35px;
  @media ${DEVICE.tablet} {
    height: 30px;
  }
`;

export const NavTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  @media ${DEVICE.laptop} {
    display: none;
  }
`;

export const SmallTabs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileNav = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: flex;
  }
`;

export const MobileTabs = styled.div`
  @media ${DEVICE.laptop} {
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }
`;

export const MobileSideNav = styled.div`
  display: none;
  @media ${DEVICE.tablet} {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    flex: 1;
  }
`;

export const HamburgerIconCont = styled.div`
  display: none;
  cursor: pointer;
  margin-left: 10px;
  @media ${DEVICE.tablet} { 
    display: block;
    padding-top: 5px;
  }
`;