import { Outlet } from "react-router-dom";
import { ParentContainer, StickyHeader, MainBody } from "./style.components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SideNav } from "../../components/shared";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokenForOpenLink, getPatientDetailAction } from "../../store/actions";
import { useState } from "react";
import {
  setSelectedPatient, patientSelector
} from "../../store/slice/patient.slice";
import OpenShareHeader from "../../components/shared/header/OpenShareHeader";

const OpenLink = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {selectedPatientId: patientId} =  useSelector(patientSelector)
  const selectedPatientId = searchParams.get("patient_id") || patientId;
  const selectedCountry = searchParams.get("country_code");
  const [isSuccess, seIstSuccess] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const data = {
        patient_id: selectedPatientId,
        country: selectedCountry,
      };
      const respose = await dispatch(getTokenForOpenLink(data));
      await dispatch(setSelectedPatient({ id: null }));
      if (respose?.payload === "Error") {
        navigate("/login");
      } else {
        seIstSuccess(true);
      }
    })();
  }, []);

  const profileDetail = async() => {
    const response = await dispatch(getPatientDetailAction());
    if(response?.payload?.status === "success") {
      await dispatch(setSelectedPatient({ id: response.payload.data.patient_id }));
      setShowProfile(true);
    }
  }

  useEffect(() => {
    if(isSuccess) {
        profileDetail();
    }
  }, [isSuccess]);

  return (
    <>
      <OpenShareHeader />
      {isSuccess ? (
        <ParentContainer>
          <SideNav />
          <MainBody>
            <Outlet />
          </MainBody>
        </ParentContainer>
      ) : null}
    </>
  );
};

export default OpenLink;
