import { Box, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ILogout, ILogoutDownArrow } from "../../../../assets";

import useIsAuthenticated from "../../../../hooks/useIsAuthenticated";
import { signoutAction } from "../../../../store/actions";
import { authSelector } from "../../../../store/slice/auth.slice";
import { COLOR_VARIANT } from "../../../../utilities/constants";
import { UserDetails } from "./style.components";

const ProfileDropdown = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const { userDetails, selectedCountry } = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    handleClose();
    if (searchParams.has('accessCode')) {
      searchParams.delete('accessCode');
      setSearchParams(searchParams);
    }
    await dispatch(signoutAction());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <UserDetails onClick={handleClick}>
        {/* <img
          src={selectedCountry?.icon}
          alt="selected_country"
          style={{ height: 20, marginRight: 10 }}
        /> */}
        <div
          style={{
            color: COLOR_VARIANT,
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#2477B3",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#000",
                }}
              >
                {userDetails?.first_name || "User"}
              </div>
              <div
                style={{
                  fontSize: "13px",
                  color: "#808080",
                }}
              >
                {userDetails?.phone_number}
              </div>
            </div>
            <img
              src={ILogoutDownArrow}
              height={5}
              alt="down-arrow"
              style={{ marginLeft: 15 }}
            />
          </div>
        </div>
      </UserDetails>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ color: "#808080", fontSize: "16px", borderRadius: "8px" }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${COLOR_VARIANT}`,
            padding: "5px 15px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <img width={20} src={ILogout} alt="Logout Icon" />
          <Typography sx={{ p: 1, color: "#808080", fontSize: "16px" }}>
            Logout / Change Country
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default ProfileDropdown;
