import { useState } from "react";
import { IHamburgerIcon, ILogoWhite  } from "../../../assets";

import MobileSideNav from "../side-nav/mobile-side-nav";
import { LogoImage, LogoImageCont, StickyHeader, HamburgerIconCont } from "./style.components";

const OpenShareHeader = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <StickyHeader>
        <LogoImageCont>
          <LogoImage src={ILogoWhite} />
        </LogoImageCont>
       <HamburgerIconCont onClick={toggleSidebar}>
        <img src={IHamburgerIcon} alt="Menu" />
      </HamburgerIconCont>
      </StickyHeader>
      <MobileSideNav
        showMobileNav={isSidebarOpen}
        setShowMobileSideNav={setIsSidebarOpen}
      />
    </>
  );
};

export default OpenShareHeader;
