import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Footer, Header } from "../../components/shared";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { MainBody, ParentContainer } from "./style.components";

const Root = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let accessCode = searchParams.get('accessCode')

    if (!isAuthenticated) {
      navigate(`/login${accessCode?'?accessCode='+accessCode:''}`);
    } else {
      if (location.pathname === "/") {
        navigate(`/home${accessCode?'?accessCode='+accessCode:''}`);
      }
      if(location.pathname.includes("open-share")) {
        const patientId = searchParams.get("patient_id");
        navigate(`/open-share/records?patientId=${patientId}`)
      }
    }
  }, [location, isAuthenticated, navigate]);

  return (
    <>
      <Header />
      <ParentContainer>
        <MainBody>
          <Outlet />
        </MainBody>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default Root;
