import { useEffect } from "react";
import { useStore } from "react-redux";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { useSelector } from "react-redux";
import { setupAxiosInstance } from "../api/api-client";
import {
  ComplianceRoot,
  Login,
  PatientList,
  PatientRoot,
  Root,
} from "../containers/";
import PatientRecords from "../containers/patient-records";
import AllRecords from "../containers/patient-records/all-records";
import { authSelector } from "../store/slice/auth.slice";
import Terms from "../containers/terms/Terms";
import InfoSection from "../components/InfoSection";
import OpenLink from "../containers/openLink";
import OpenLinkPatientRecords from "../containers/patient-records/OpenLinkPatientRecords";
import QuestionnaireForm from "../containers/questionnaire/questionnaire-form";
import QuestionnaireList from "../containers/questionnaire/questionnaire-list";

const router = createBrowserRouter([
  
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/terms-conditions",
    element: <Terms/>,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <PatientList />,
      },
      {
        path: "home",
        element: <PatientList />,
      },
    ],
  },
  {
    path: "/open-share/profile",
    element: <OpenLink />,
    children: [
      {
        index: true,
        element: <OpenLinkPatientRecords />,
      },
      {
        path: "scan-info",
        element: <InfoSection />,
      },
      {
        path: "all-records",
        element: <AllRecords />,
      },
      {
        path: "questionnaire-type/questionnaire-form",
        element: <QuestionnaireForm />,
      },
      {
        path: "questionnaire-list",
        element: <QuestionnaireList />,
      },
      {
        path: "questionnaire-list/questionnaire-type/questionnaire-form",
        element: <QuestionnaireForm />,
      },
    ]
  },
  {
    path: "/open-share/record",
    element: <OpenLink />,
    children: [
      {
        index: true,
        element: <OpenLinkPatientRecords />,
      },
      {
        path: "scan-info",
        element: <InfoSection />,
      },
      {
        path: "all-records",
        element: <AllRecords />,
      },
    ]
  },
  {
    path: "/records",
    element: <PatientRoot />,
    children: [
      {
        index: true,
        element: <PatientRecords />,
      },
      {
        path: "all-records",
        element: <AllRecords />,
      },
      {
        path: "scan-info",
        element: <InfoSection />,
      },
      {
        path: "questionnaire-type/questionnaire-form",
        element: <QuestionnaireForm />,
      },
      {
        path: "questionnaire-list",
        element: <QuestionnaireList />,
      },
      {
        path: "questionnaire-list/questionnaire-type/questionnaire-form",
        element: <QuestionnaireForm />,
      },
    ],
  },
]);

const RootRouter = () => {
  const { isOTPVerified, userDetails } = useSelector(authSelector);
  const store = useStore();
  setupAxiosInstance(store);
  useEffect(() => {
    if (isOTPVerified && userDetails?.access_token) {
      redirect("/");
    } else {
      redirect("/login");
    }
  }, [isOTPVerified, userDetails]);

  return <RouterProvider router={router} />;
};

export default RootRouter;
