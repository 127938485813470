import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  IDobIcon,
  IGenderIcon,
  IBackArrow,
  IProfileImageSmall,
  IPhoneIcon,
  IEmailIcon
} from "../../../assets";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  ArrowBackImage,
  MobileSideNavbar,
  PatientName,
  PhoneNumber,
  ProfileIcon,
  ProfileInfo,
  ProfileInfoContainer,
  ProfileWrapper,
  TopHeader,
  TopHeaderBackContainer,
  EmailItem,
  EmailImage,
  EmailText,
} from "./style.components";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const MobileSideNav = ({ showMobileNav, setShowMobileSideNav }) => {
  const navigate = useNavigate();
  const { patients, selectedPatientId } = useSelector(patientSelector);
  const patientRecord = patients?.filter(
    (patient) => patient?.patient_id === selectedPatientId
  );
  const location = useLocation();
  const isOpenShare = location.pathname.includes("open-share");

  const phone_number = patientRecord?.[0]?.phone_number;
  const patient_name = patientRecord?.[0]?.patient_name;
  const gender = patientRecord?.[0]?.gender;
  const dob = patientRecord?.[0]?.dob;
  const email = patientRecord?.[0]?.email;

  const PATH_TO_IGNORE = ["/home", "/"];

  return (
    <MobileSideNavbar showNav={showMobileNav}>
      <TopHeader>
        {!PATH_TO_IGNORE.includes(location.pathname) && !isOpenShare ? (
          <TopHeaderBackContainer onClick={() => navigate("/home")}>
            <ArrowBackImage src={IBackArrow} alt="back-arrow" />
            Home
          </TopHeaderBackContainer>
        ) : (
          <TopHeaderBackContainer />
        )}

        <IconButton onClick={() => setShowMobileSideNav(false)}>
          <CloseIcon />
        </IconButton>
      </TopHeader>
      {!PATH_TO_IGNORE.includes(location.pathname) ? (
        <>
          <ProfileWrapper>
            <ProfileIcon src={IProfileImageSmall} alt="Profile Icon" />
          </ProfileWrapper>
          <Box sx={{ marginLeft: "24px" }}>
            <PatientName>{patient_name}</PatientName>
            {phone_number && (
              <EmailItem>
                <EmailImage src={IPhoneIcon} alt="phone"></EmailImage>
                <EmailText>{phone_number}</EmailText>
              </EmailItem>
            )}
            {email && (
              <EmailItem>
                <EmailImage src={IEmailIcon} alt="email"></EmailImage>
                <EmailText>{email}</EmailText>
              </EmailItem>
            )}
            <Box>
              {gender && (
                <ProfileInfo>
                  <ArrowBackImage src={IGenderIcon} alt="gender-icon" />
                  {`${gender?.charAt(0)?.toUpperCase()}${gender?.slice(1)}`}
                </ProfileInfo>
              )}
              {dob && (
                <ProfileInfo>
                  <ArrowBackImage src={IDobIcon} alt="calendar-icon" />
                  {moment(dob, "DD-MM-YYYY").format("DD-MMM-YYYY")} (
                  {moment().diff(moment(dob, "DD-MM-YYYY"), "years")} Years)
                </ProfileInfo>
              )}
            </Box>
          </Box>
        </>
      ) : null}
    </MobileSideNavbar>
  );
};

export default MobileSideNav;
