import { createSlice } from "@reduxjs/toolkit";
import {
  generateOTPAction,
  getSupportedCountriesAction,
  signoutAction,
  verifyOTPAction,
  getVerifyAccessToken,
  getTokenForOpenLink,
} from "../actions";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  userDetails: null,
  isOTPVerified: null,
  isLoggedOut: null,
  supportedCountries: null,
  selectedCountry: null,
  isOpenShare: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    selectedCountry: (state, { payload }) => {
      state.selectedCountry = payload;
    },
  },
  extraReducers: (builder) => {
    // generate otp
    builder.addCase(generateOTPAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateOTPAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = true;
    });
    builder.addCase(generateOTPAction.rejected, (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    });
    // verify otp
    builder.addCase(verifyOTPAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOTPAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isOTPVerified = true;
      state.userDetails = payload?.patient || null;
    });
    builder.addCase(verifyOTPAction.rejected, (state) => {
      state.isLoading = false;
      state.isOTPVerified = false;
    });
    // signout
    builder.addCase(signoutAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signoutAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isLoggedOut = true;
      state.isAuthenticated = false;
      state.userDetails = null;
      state.isOTPVerified = null;
      state.selectedCountry = null;
      state.supportedCountries = null;
    });
    builder.addCase(signoutAction.rejected, (state) => {
      state.isLoading = false;
      state.isLoggedOut = false;
    });
    // get supported countries
    // signout
    builder.addCase(getSupportedCountriesAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSupportedCountriesAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.supportedCountries = payload.data;
      }
    );
    builder.addCase(getSupportedCountriesAction.rejected, (state) => {
      state.isLoading = false;
      state.supportedCountries = null;
    });

    // // getting access token
    builder.addCase(getVerifyAccessToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVerifyAccessToken.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isOTPVerified = true;
      state.userDetails = payload?.patient || null;
      state.isOpenShare = false;
    });
    builder.addCase(getVerifyAccessToken.rejected, (state) => {
      state.isLoading = false;
      state.isOTPVerified = false;
      state.isOpenShare = false;
    });

    // getting open share token
    builder.addCase(getTokenForOpenLink?.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTokenForOpenLink?.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isOTPVerified = false;
      state.isOpenShare = true;
      state.userDetails = payload?.result || null;
    });
    builder.addCase(getTokenForOpenLink?.rejected, (state) => {
      state.isLoading = false;
      state.isOTPVerified = false;
      state.isOpenShare = false;
    });
  },
});

export const authActions = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;
