import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Styled from "./style.components";
import { 
    IBackArrow
} from "../../../assets";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { getFormFilledAction } from "../../../store/actions";
import { patientSelector } from "../../../store/slice/patient.slice";
import { SnackBar } from "../../../components/shared";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const QuestionnaireForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [formID, setFormID] = useState(null);
    const [filledFormID, setFilledFormID] = useState(null);
    const [formTitle, setFormTitle] = useState('');
    const [formTemplateArray, setFormTemplateArray] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const { selectedPatientId } = useSelector(patientSelector);
    const [formInputs, setFormInputs] = useState({});
    const [selectedOption, setSelectedOption] = useState({});
    useEffect(() => {
        const id = location.state?.form_id;
        setFormID(id);
        const idForFilledForm = location.state?.filled_form_id;
        setFilledFormID(idForFilledForm);
        const formTitle = location.state?.form_type;
        setFormTitle(formTitle);
    }, [location]);
    
    useEffect(() => {
        if (filledFormID) {
            fetchFilledFormTemplate();
        }
    }, [filledFormID, dispatch]);        

    useEffect(() => {
        if (filledFormID && formTemplateArray.length > 0) {
            const initialFormInputs = {};
            const initialSelectedOptions = {};
            const initialSelectedCheckboxes = {};
    
            formTemplateArray.forEach(element => {
                if (element.answer_type === 'choice') {
                    initialSelectedOptions[element.id] = element.answer[0];
                } else if (element.answer_type === 'open-choice') {
                    // Initialize checkboxes state based on the answers
                    initialSelectedCheckboxes[element.id] = element.answer_option.reduce((acc, option) => {
                        acc[option.valueString] = element.answer.includes(option.valueString);
                        return acc;
                    }, {});
                } else if (element.answer_type === 'date') {
                    initialFormInputs[element.id] = element.answer[0];
                } else {
                    initialFormInputs[element.id] = element.answer[0];
                }
            });
    
            setFormInputs(initialFormInputs);
            setSelectedOption(initialSelectedOptions);
            setSelectedCheckboxes(initialSelectedCheckboxes);
        }
    }, [filledFormID, formTemplateArray]);
    
    const determineIfColumnFlexNeeded = (element) => {
        return (element.answer_type === 'choice' || element.answer_type === 'open-choice') && (
            element.answer_option.some(option => option.valueString && option.valueString.split(' ').length > 1) ||
            new Set(element.answer_option.map(option => option.valueString)).size > 3
        );
    };

    const fetchFilledFormTemplate = async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getFormFilledAction(filledFormID)).unwrap();
            setFormTemplateArray(response?.result || []);
        } catch (error) {
            console.error("Failed to fetch form template:", error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Styled.Container><ShimmerPostDetails card cta variant="SIMPLE" /></Styled.Container>;
    }

    const handleRadioChange = (id, value) => {
        setSelectedOption(prev => {
            const updatedOptions = {...prev, [id]: value};
            return updatedOptions;
        });
    };
    
    const handleCheckboxChange = (id, value) => {
        setSelectedCheckboxes(prev => {
            const updatedCheckboxes = {
                ...prev,
                [id]: {
                    ...prev[id],
                    [value]: !prev[id]?.[value]
                }
            };
            return updatedCheckboxes;
        });
    };

    const handleInputChange = (id, value) => {
        setFormInputs(prev => ({
            ...prev,
            [id]: value
        }));
    };
    
    const renderFormElement = (element) => {
        const isColumnFlex = determineIfColumnFlexNeeded(element);
        const isFilledForm = !!filledFormID;
    
        switch (element.answer_type) {
            case 'string':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                        {isFilledForm ? (
                            <Styled.PlainTextAnswer>{formInputs[element.id]}</Styled.PlainTextAnswer>
                        ) : (
                            <Styled.InputAnswer 
                                type="text" 
                                required={element.is_required} 
                                onChange={(e) => handleInputChange(element.id, e.target.value)}
                                value={formInputs[element.id] || ''}
                            />
                        )}
                    </Styled.FormElementContainer>
                );
            
            case 'choice':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                        {isFilledForm ? (
                            <Styled.PlainTextAnswer>{selectedOption[element.id]}</Styled.PlainTextAnswer>
                        ) : (
                            <Styled.InputContainer columnFlex={isColumnFlex}>
                                {element.answer_option.map((option, index) => (
                                    <Styled.RadioContainer 
                                        key={index} 
                                        isSelected={selectedOption[element.id] === option.valueString}
                                        columnFlex={isColumnFlex}
                                        onClick={() => !isFilledForm && handleRadioChange(element.id, option.valueString)}
                                    >
                                        <Styled.RadioAnswer 
                                            type="radio" 
                                            id={`${element.id}-${option.valueString}`} 
                                            name={element.id} 
                                            value={option.valueString || ''}
                                            onChange={() => handleInputChange(element.id, option.valueString)} 
                                            checked={selectedOption[element.id] === option.valueString}
                                            disabled={isFilledForm}
                                        />
                                        <Styled.RadioLabel htmlFor={`${element.id}-${option.valueString}`}>{option.valueString}</Styled.RadioLabel>
                                    </Styled.RadioContainer>
                                ))}
                            </Styled.InputContainer>
                        )}
                    </Styled.FormElementContainer>
                );
    
                case 'open-choice':
                    return (
                        <Styled.FormElementContainer key={element.id}>
                            <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                            {isFilledForm ? (
                                <Styled.PlainTextAnswer>
                                    {Object.keys(selectedCheckboxes[element.id] || {})
                                        .filter(key => selectedCheckboxes[element.id][key])
                                        .join(", ")}
                                </Styled.PlainTextAnswer>
                            ) : (
                                <Styled.InputContainer columnFlex={isColumnFlex}>
                                    {element.answer_option.map((option, index) => (
                                        <Styled.CheckboxContainer 
                                            key={index}
                                            isSelected={selectedCheckboxes[element.id]?.[option.valueString]}
                                            columnFlex={isColumnFlex}>
                                            <Styled.CheckboxAnswer 
                                                type="checkbox" 
                                                id={`${element.id}-${option.valueString}`} 
                                                name={element.id} 
                                                value={option.valueString || ''} 
                                                checked={selectedCheckboxes[element.id]?.[option.valueString]}
                                                onChange={() => handleCheckboxChange(element.id, option.valueString)}
                                                disabled={isFilledForm}
                                            />
                                            <Styled.CheckboxLabel 
                                                htmlFor={`${element.id}-${option.valueString}`}>
                                                {option.valueString}
                                            </Styled.CheckboxLabel>
                                        </Styled.CheckboxContainer>
                                    ))}
                                </Styled.InputContainer>
                            )}
                        </Styled.FormElementContainer>
                    );
                
    
            case 'date':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                        {isFilledForm ? (
                            <Styled.PlainTextAnswer>{formInputs[element.id]}</Styled.PlainTextAnswer>
                        ) : (
                            <Styled.DateInput 
                                type="date" 
                                required={element.is_required}
                                onChange={(e) => handleInputChange(element.id, e.target.value)}
                                value={formInputs[element.id] || ''}
                            />
                        )}
                    </Styled.FormElementContainer>
                );

            case 'boolean':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                        {isFilledForm ? (
                            <Styled.PlainTextAnswer>
                                {formInputs[element.id] ? 'Yes' : 'No'}
                            </Styled.PlainTextAnswer>
                        ) : (
                            <Styled.BooleanContainer 
                                isSelected={formInputs[element.id]}
                                columnFlex={false}
                            >
                                <Styled.CheckboxAnswer 
                                    type="checkbox" 
                                    id={`boolean-${element.id}`} 
                                    name={element.id} 
                                    checked={formInputs[element.id] || false}
                                    onChange={(e) => handleInputChange(element.id, e.target.checked)}
                                    disabled={isFilledForm}
                                />
                                <Styled.CheckboxLabel htmlFor={`boolean-${element.id}`}>{element.question}</Styled.CheckboxLabel>
                            </Styled.BooleanContainer>
                        )}
                    </Styled.FormElementContainer>
                );
    
            default:
                return null;
        }
    };
        

    return(
        <Styled.Container>
            <Styled.TopHeader>
            <Styled.ArrowBackImage 
                src={IBackArrow} 
                alt="back-arrow" 
                onClick={() => navigate(-1)}
            />
            Questionnaire
            </Styled.TopHeader>
            <Styled.TypeContainer>
            <Styled.TypeText>{formTitle}</Styled.TypeText>
            <Styled.Form>
                {formTemplateArray.map(element => renderFormElement(element))}
                {!filledFormID && <Styled.SaveButton type="submit">Save</Styled.SaveButton>}
            </Styled.Form>
          </Styled.TypeContainer>
          <SnackBar />
        </Styled.Container>
    )
}

export default QuestionnaireForm;