import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const generateOTP = async (data) => {
  try {
    const response = await apiClient.post(
      API_ENDPOINT.GENERATE_OTP,
      data,
      false
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const verifyOTP = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.VERIFY_OTP_VALIDATE, data, false);
    return response;
  } catch (error) {
    console.error(error);    return Promise.reject(error);
  }
};

const generateTokenForOpenLink = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.GENERATE_TOKEN, data, false);
    return response;
  } catch (error) {
    console.error(error);    return Promise.reject(error);
  }
};

const signout = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.LOGOUT);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getSupportedCountries = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.GET_SUPPORTED_COUNTRIES);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getAccessToken = async (data) => {
  try {
    const res  = await fetch(API_ENDPOINT.GET_ACCESS_TOKEN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.validationToken}`,
      },
      body: JSON.stringify({country : data.country}),
    })
    const response =  await res.json()
    return response;
  } catch (error) {
    console.error(error);    return Promise.reject(error);
  }
};


export { generateOTP, getSupportedCountries, signout, verifyOTP,getAccessToken, generateTokenForOpenLink };
