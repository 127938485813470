import moment from "moment";
import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IDobIcon,
  IGenderIcon,
  IBackArrow,
  IProfileImageSmall,
  IPhoneIcon,
  IEmailIcon,
} from "../../../assets";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  ArrowBackImage,
  PatientName,
  ProfileIcon,
  ProfileInfo,
  ProfileInfoContainer,
  ProfileWrapper,
  SideNavbar,
  TopHeader,
  EmailImage,
  EmailText,
  EmailItem
} from "./style.components";

const SideNav = () => {
  const navigate = useNavigate();
  const { patients, selectedPatientId } = useSelector(patientSelector);
  const patientRecord = patients?.filter(
    (patient) => patient?.patient_id === selectedPatientId
  );
  const phone_number = patientRecord?.[0]?.phone_number;
  const patient_name = patientRecord?.[0]?.patient_name;
  const gender = patientRecord?.[0]?.gender;
  const dob = patientRecord?.[0]?.dob;
  const email = patientRecord?.[0]?.email;
  const location = useLocation();
  const containsOpenShare = location.pathname.includes("open-share");
  
  return (
    <SideNavbar>
      {!containsOpenShare && (
        <TopHeader onClick={() => navigate("/home")}>
          <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
            <ArrowBackImage src={IBackArrow} alt="back-arrow" />
            Home
          </Box>
        </TopHeader>
      )}
      <ProfileWrapper>
        <ProfileIcon src={IProfileImageSmall} alt="Profile Icon" />
      </ProfileWrapper>

      <ProfileInfoContainer>
        <PatientName>{patient_name}</PatientName>
        {phone_number && (
          <EmailItem>
            <EmailImage src={IPhoneIcon} alt="phone"></EmailImage>
            <EmailText>{phone_number}</EmailText>
          </EmailItem>
        )}
        {email && (
          <EmailItem>
            <EmailImage src={IEmailIcon} alt="email"></EmailImage>
            <EmailText>{email}</EmailText>
          </EmailItem>
        )}
        {gender && (
          <ProfileInfo>
            <ArrowBackImage src={IGenderIcon} alt="gender" />
            {gender
              ? `${gender?.charAt(0)?.toUpperCase()}${gender?.slice(1)}`
              : "-"}
          </ProfileInfo>
        )}
        {dob && (
          <ProfileInfo>
            <ArrowBackImage src={IDobIcon} alt="calendar" />
            {dob ? moment(dob, "DD-MM-YYYY").format("DD-MMM-YYYY") : "-"} (
            {moment().diff(moment(dob, "DD-MM-YYYY"), "years")} Years)
          </ProfileInfo>
        )}
      </ProfileInfoContainer>
    </SideNavbar>
  );
};

export default SideNav;
