import React, { useEffect, useState } from "react";
import { IBackArrow, INextArrow } from "../../assets";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../../components/shared";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  TopHeader,
  CardsContainer,
  Container,
  ListDateTitle,
  ListItemDate,
  ListItemWrapper,
  ListSection,
  ListWrapper,
  LoaderWrapper,
  ReviewButtonWrapper,
  StyledCloseButton,
  StyledImage,
  StyledModal,
  StyledModalContent,
  ListItemTop,
  NextArrow,
  SeeMoreText,
  SeeMoreWrapper,
} from "./style.components";
import { Grid, CircularProgress, Box } from "@mui/material";
import { GlobalWorkerOptions } from "pdfjs-dist";
import {
  getRecordsAction,
  getThumbnails,
  getReportURL,
} from "../../store/actions";
import RecordItem from "../../components/layout/RecordItem/RecordItem";
import NoteRecordItem from "../../components/layout/NoteRecordItem/NoteRecordItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { resetThumbnail } from "../../store/slice/thumbnail.slice";
import PhotoRecordItem from "../../components/layout/PhotoRecordItem/PhotoRecordItem";

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const AllRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { recordType, recordName, patientID } = location.state || {};
  const [recordsData, setRecordsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;
  const [isloadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const [pageToken, setPageToken] = useState("");
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [loadingSeeMore, setLoadingSeeMore] = useState(false);

  useEffect(() => {
    if (patientID) {
      setIsLoading(true);
      fetchRecords(recordType);
    }
  }, [patientID, recordType, dispatch]);

  useEffect(() => {
    dispatch(resetThumbnail());
    const interval = setInterval(() => {
      fetchThubnailImages(true);
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const fetchThubnailImages = async (isResetThumbnail=false) => {
    if (
      recordType === "scans" ||
      recordType === "notes" ||
      recordType === "questionnaire"
    )
      return;
    const filePaths = recordsData
    .filter((item) => item?.file_path && (isResetThumbnail || !thumbnails?.[item.id]))
    .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnails({
          file_objects: filePaths,
        })
      ).then(() => {
        setIsLoadingThumbnail(!isloadingThumbnail);
      });
  };

  useEffect(() => {
    if (recordsData.length) fetchThubnailImages();
  }, [recordsData]);

  const closeModal = () => {};

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      return response?.payload?.data;
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };
  const handleSeeMore = () => {
    setLoadingSeeMore(true);
    fetchRecords(recordType);
  };

  const fetchRecords = async (recordType, isUpdate=false) => {
    if (patientID) {
      try {
        const action = pageToken
          ? await dispatch(
              getRecordsAction({
                record_type: recordType,
                patient_id: patientID,
                limit: isUpdate ? recordsData.length : 10,
                page_token: pageToken,
              })
            )
          : await dispatch(
              getRecordsAction({
                record_type: recordType,
                patient_id: patientID,
                limit: 10,
              })
            );
        if (action.payload?.status === "success") {
          const fetchedData = action.payload.data;
          const totalCount = action.payload.total;
          const page_token = action.payload.page_token;
          isUpdate ?  setRecordsData([...fetchedData]) : setRecordsData([...recordsData, ...fetchedData]);
          setTotal(totalCount);
          setPageToken(page_token);
        }
      } catch (error) {
        console.error("Error fetching records:", error);
      } finally {
        setLoadingSeeMore(false);
        setIsLoading(false);
      }
    }
  };

  const handleScanClick = (fhirId) => {
    navigate("/records/scan-info", {
      state: {
        studyFhirId: fhirId,
      },
    });
  };

  const handleListItemClick = async (record) => {
    if (record?.record_type === "scans") {
      const url = isMobileWidth
        ? record?.mobile_viewer_url
        : record?.dicom_viewer_url;
      const newWindow = window.open("", "_blank");
      if (newWindow) newWindow.location.href = url;
    } else if (record?.record_type === "questionnaire") {
      navigate("questionnaire-type/questionnaire-form", {
        state: { filled_form_id: record?.id },
      });
    } else if (record?.name === "Notes") {
      return;
    } else if (
      record?.record_type !== "questionnaire" ||
      record?.record_type !== "scans" ||
      record?.record_type !== "notes"
    ) {
      const url = await fetchReportUrl(record?.file_path);
      setSelectedRecord({ ...record, url: url });
      setOpen(true);
    }
  };

  return (
    <Container>
      <TopHeader>
        <ChevronLeftIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        Back
      </TopHeader>
      <ListWrapper>
        <>
          <ListSection>
            <ListDateTitle>
              {recordName} { total > 0 ? `(${total})` : ''}
            </ListDateTitle>
          </ListSection>
          {!isLoading ? (
          <Grid container mt={3} gap={2}>
          {(() => {
            switch (recordType) {
              case "scans":
                return (
                  <>
                    {recordsData?.map((row, index) => (
                      <RecordItem
                        key={row.id}
                        reportType={"scan"}
                        fetchUrlForFile={() => handleScanClick(row?.id)}
                        isShowDropdown={false}
                        row={row}
                        index={index}
                      />
                    ))}
                    {total > 9 &&
                      recordsData.length < total &&
                      (!loadingSeeMore ? (
                        <ListItemWrapper key="see-more">
                          <ListItemTop style={{ visibility: "hidden" }}>
                            hidden
                          </ListItemTop>
                          <SeeMoreWrapper onClick={handleSeeMore}>
                            <SeeMoreText>See More</SeeMoreText>
                            <NextArrow src={INextArrow} />
                          </SeeMoreWrapper>
                          {/* Hidden ListItemDate elements */}
                          {Array.from({ length: 6 }).map((_, i) => (
                            <ListItemDate
                              key={i}
                              style={{ visibility: "hidden" }}
                            >
                              hidden
                            </ListItemDate>
                          ))}
                        </ListItemWrapper>
                      ) : (
                        <Box
                          display="flex"
                          m={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />{" "}
                        </Box>
                      ))}
                  </>
                );
              case "notes":
                return (
                  <>
                    {recordsData?.map((row, index) => (
                      <NoteRecordItem row={row} index={index} />
                    ))}
                    {total > 9 &&
                      recordsData.length < total &&
                      (!loadingSeeMore ? (
                        <ListItemWrapper key="see-more">
                          <Box
                            onClick={handleSeeMore}
                            sx={{
                              width: 180,
                              height: 260,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "8px",
                              textAlign: "center",
                              background: "#ebf7fb",
                            }}
                          >
                            <SeeMoreText>See More</SeeMoreText>
                            <NextArrow src={INextArrow} />
                          </Box>
                        </ListItemWrapper>
                      ) : (
                        <Box
                          display="flex"
                          m={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />{" "}
                        </Box>
                      ))}
                  </>
                );
              case "image":
                return (
                  <>
                    {recordsData?.map((row, index) => (
                      <PhotoRecordItem row={row} index={index} />
                    ))}
                    {total > 9 &&
                      recordsData.length < total &&
                      (!loadingSeeMore ? (
                        <ListItemWrapper key="see-more">
                          <Box
                            onClick={handleSeeMore}
                            sx={{
                              width: 180,
                              height: 200,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "8px",
                              textAlign: "center",
                              background: "#ebf7fb",
                            }}
                          >
                            <SeeMoreText>See More</SeeMoreText>
                            <NextArrow src={INextArrow} />
                          </Box>
                        </ListItemWrapper>
                      ) : (
                        <Box
                          display="flex"
                          m={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />{" "}
                        </Box>
                      ))}
                  </>
                );
              default:
                return (
                  <>
                    {recordsData?.map((row, index) => (
                      <RecordItem
                        key={row.id}
                        reportType={recordType}
                        isShowDropdown={false}
                        fetchUrlForFile={() => handleListItemClick(row)}
                        row={row}
                        index={index}
                        thumbnailData={thumbnails}
                      />
                    ))}
                    {total > 9 &&
                      recordsData.length < total &&
                      (!loadingSeeMore ? (
                        <ListItemWrapper key="see-more">
                          <ListItemTop style={{ visibility: "hidden" }}>
                            hidden
                          </ListItemTop>
                          <SeeMoreWrapper onClick={handleSeeMore}>
                            <SeeMoreText>See More</SeeMoreText>
                            <NextArrow src={INextArrow} />
                          </SeeMoreWrapper>
                          {/* Hidden ListItemDate elements */}
                          {Array.from({ length: 6 }).map((_, i) => (
                            <ListItemDate
                              key={i}
                              style={{ visibility: "hidden" }}
                            >
                              hidden
                            </ListItemDate>
                          ))}
                        </ListItemWrapper>
                      ) : (
                        <Box
                          display="flex"
                          m={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />{" "}
                        </Box>
                      ))}
                  </>
                );
            }
          })()}
        </Grid>
        
          ) : (
            <ShimmerContentBlock
              title
              text
              cta
              thumbnailWidth={82}
              thumbnailHeight={82}
            />
          )}
        </>
      </ListWrapper>
      <StyledModal open={open} onClose={closeModal}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {selectedRecord &&
            (["image/jpeg", "image/png"].includes(
              selectedRecord.content_type
            ) || selectedRecord?.record_type === "image" ? (
              <StyledImage src={selectedRecord.url} alt={selectedRecord.name} />
            ) : (
              <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={selectedRecord.url} />
              </Worker>
            ))}
        </StyledModalContent>
      </StyledModal>
      <SnackBar />
    </Container>
  );
};

export default AllRecords;
