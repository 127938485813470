import styled from "styled-components";
import {
    COLOR_PRIMARY_LIGHT,
    FOOTER_HEIGHT,
    HEADER_HEIGHT,
    DEVICE
  } from "../../utilities/constants";

export const ParentContainer = styled.div`
  display: flex;
  background-color: ${COLOR_PRIMARY_LIGHT};
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
`;

export const StickyHeader = styled.div`
  background-color: #F5FBFC;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  @media ${DEVICE.tablet} {
    padding: 0px 15px;
  }
`;

export const MainBody = styled.div`
  width: 100%;

  @media ${DEVICE.laptop} {
    padding: 0px 18px;
  }
  @media ${DEVICE.tablet} {
    padding: 0px;
  }
`;
